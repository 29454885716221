import {v2Request} from "./v2Request";


export const clubV2Api = {


  startPayClub({discount, payType, paySubType}) {
    return v2Request.post(`club/startPayClub`, {payType, paySubType, discount});
  },


  findMyDiscountExpireTime() {
    return v2Request.post(`club/findMyDiscountExpireTime`);
  },


  findMyHasPayClub(){
    return v2Request.post(`club/findMyHasPayClub`);
  }


}

<template>
  <div class="discount-pay">
    <img class="logo" src="https://www.001ppt.com/_nuxt/img/logo.4207d53.svg" alt="">
    <div class="title">加入俱乐部会员</div>
    <div class="price">
      <template v-if="expireMs>0">¥398</template>
      <template v-else>¥498</template>
    </div>
    <div class="price-desc">已减¥100津贴</div>
    <div class="pay-btn" v-if="!paySuccess" @click="startPay">马上加入</div>
    <div class="success" v-else>
      <van-icon name="checked"/>
    </div>
    <div class="discount" v-if="expireMs>0">
      <div style="width: 240px">优惠剩余期限 {{ expireStr }}</div>
    </div>
  </div>
</template>


------------------ script ------------------
<script>
import wxValidate from "../../config/wx.validate";
import {mapGetters} from "vuex";
import {sleep} from "../../config/util";
import {clubV2Api} from "../../api/v2/clubV2Api";

export default {
  mixins: [wxValidate],
  computed: {
    ...mapGetters(['userInfo']),
    expireStr() {
      if (this.expireMs < 0) {
        return '';
      }
      const h = Math.floor(this.expireMs / 60 / 60 / 1000);
      const m = Math.floor((this.expireMs - h * 60 * 60 * 1000) / 60 / 1000)
      const s = Math.floor((this.expireMs - h * 60 * 60 * 1000 - m * 60 * 1000) / 1000)
      const ms = this.expireMs - h * 60 * 60 * 1000 - m * 60 * 1000 - s * 1000
      return `${h}时 ${m}分 ${s}秒 ${ms}`
    }
  },
  async created() {
    this.wxSignature().then()
    this.loadClubDiscount().then();
  },

  data() {
    return {
      paySuccess: false,
      expireMs: 0,
    }
  },


  methods: {
    async loadClubDiscount() {
      const clubDiscountExpireTime = await clubV2Api.findMyDiscountExpireTime()
      if (clubDiscountExpireTime) {
        const expireTime = new Date((clubDiscountExpireTime + '').replace(/-/g, '/'))
        const interval = setInterval(() => {
          const expireMs = expireTime.getTime() - Date.now()
          if (expireMs <= 0) {
            this.expireMs = 0
            return clearInterval(interval)
          }
          this.expireMs = expireMs
        }, 50)
      }
    },

    async startPay() {
      if (!this.userInfo || !Object.keys(this.userInfo).length) {
        return this.$store.commit('SHOW_LOGIN_DIALOG');
      }
      try {
        this.$loading('请求中');
        await sleep(500)
        const res = await clubV2Api.startPayClub({discount: true, payType: 1, paySubType: 11});
        wx.chooseWXPay({
          timestamp: res.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: res.nonceStr, // 支付签名随机串，不长于 32 位
          package: res.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: res.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: res.paySign, // 支付签名
          success: async (r) => {
            // 支付成功后的回调函数
            this.paySuccess = true;
          },
          fail: (e) => {
            this.$toast.error(e.errMsg);
          },
        });
      } finally {
        this.$loading.close();
      }
    }
  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
.discount-pay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //position: fixed;
  //left: 0;
  //right: 0;
  //top: 0;
  //bottom: 0;
  padding-top: 100px;
}

.logo {
  width: 60vw;
}

.title {
  line-height: 32px;
  font-size: 14px;
}

.price {
  color: var(--red-color);
  font-size: 32px;
  font-weight: bolder;
  padding-top: 32px;
  padding-bottom: 4px;
}

.price-desc {
  color: var(--grey-color);
  font-size: 14px;
  padding-bottom: 64px;
}

.pay-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 60vw;
  margin: 16px auto;
  background: #151d36;
  color: #fff;
  border-radius: 100px;
  font-size: 18px;
}

.success {
  font-size: 75px;
  color: var(--weixin);
}


.discount {
  color: #ee4a50;
  display: flex;
  align-items: center;
  justify-content: center;
  //position: fixed;
  //bottom: 32px;
  flex-direction: column;
}

</style>
